
import { Component, Prop, Vue } from "vue-property-decorator";
import { mapGetters } from "vuex";
import { MODAL_RESULTS } from "@/constants";
import { IModalResultList } from "@/models/Global";

@Component({
  computed: {
    ...mapGetters("appraiser", {
      loading: "appraiserLoading",
    }),
  },
})
export default class AppraiserFormResultStep extends Vue {
  @Prop({ type: String, default: null })
  protected result?: string;

  protected modalResults: IModalResultList = MODAL_RESULTS;
}
