
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import {
  IAppraiserFormExtraData,
  IAppraiserPropertyCharacteristicsList,
  IAppraiserPropertyIssuesList,
  IAppraiserPropertyStatusList,
  IAppraiserPropertyTypesList,
  IAppraiserRefurbishmentTypesList,
} from "@/models/Appraiser";
import {
  APPRAISER_FORM_PROPERTY_CHARACTERISTICS,
  APPRAISER_FORM_PROPERTY_ISSUES,
  APPRAISER_FORM_PROPERTY_STATUS,
  APPRAISER_FORM_PROPERTY_TYPE_IDS,
  APPRAISER_FORM_REFURBISHMENT_TYPES,
} from "@/constants/tools";
import { IRadioItems, ISelectOption } from "@/models/Global";

@Component
export default class AppraiserFormExtraDataStep extends Vue {
  @Prop({ type: Number, default: null })
  protected constructionYear?: number;

  protected propertyExtraData: IAppraiserFormExtraData = {
    propertyTypeId: 2,
    constructionYear: this.constructionYear,
    numRooms: 0,
  };

  protected propertyTypeIds: IAppraiserPropertyTypesList =
    APPRAISER_FORM_PROPERTY_TYPE_IDS;

  protected propertyStatus: IAppraiserPropertyStatusList =
    APPRAISER_FORM_PROPERTY_STATUS;

  protected propertyCharacteristics: IAppraiserPropertyCharacteristicsList =
    APPRAISER_FORM_PROPERTY_CHARACTERISTICS;

  protected propertyIssues: IAppraiserPropertyIssuesList =
    APPRAISER_FORM_PROPERTY_ISSUES;

  protected refurbishmentTypes: IAppraiserRefurbishmentTypesList =
    APPRAISER_FORM_REFURBISHMENT_TYPES;

  protected get propertyTypeOptions(): ISelectOption[] {
    return Object.values(this.propertyTypeIds).map((propertyTypeId: number) => {
      return {
        label: this.$tc("property_type_" + propertyTypeId, 2),
        value: propertyTypeId,
      };
    });
  }

  protected get propertyStatusItems(): IRadioItems[] {
    return Object.values(this.propertyStatus).map((propertyStatus: string) => {
      return {
        label: this.$tc(propertyStatus, 2),
        value: propertyStatus,
      };
    });
  }

  protected get refurbishmentTypeItems(): IRadioItems[] {
    return Object.values(this.refurbishmentTypes).map(
      (refurbishmentType: string) => {
        return {
          label: this.$tc(refurbishmentType + "Refurbishment", 2),
          value: refurbishmentType,
        };
      }
    );
  }

  protected get binaryOptionItems(): IRadioItems[] {
    return [
      {
        label: this.$tc("yes", 2),
        value: true,
      },
      {
        label: this.$tc("no", 2),
        value: false,
      },
    ];
  }

  @Emit("next")
  nextStep() {
    return this.propertyExtraData;
  }
}
