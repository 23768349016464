
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import { IAppraiserCadastralPropertyUnit } from "@/models/Appraiser";
import { IRadioItems } from "@/models/Global";

@Component
export default class AppraiserFormPropertyUnitStep extends Vue {
  @Prop({ type: Array, required: true })
  protected propertyUnits!: IAppraiserCadastralPropertyUnit[];

  protected selectedPropertyUnitCadastralReference = null;

  protected get propertyUnitItems(): IRadioItems[] {
    return this.propertyUnits.map((property) => {
      property.label = property.label || this.$tc("property_type_5", 2);
      return {
        label: property.label,
        value: property.cadastralReference,
      };
    });
  }

  @Emit("next")
  nextStep() {
    return this.propertyUnits.find((propertyUnit) => {
      return (
        propertyUnit.cadastralReference ===
        this.selectedPropertyUnitCadastralReference
      );
    });
  }
}
