
import { Component, Emit, Vue } from "vue-property-decorator";
import { Validations } from "vuelidate-property-decorators";
import {
  alphaNum,
  minLength,
  maxLength,
  requiredIf,
} from "vuelidate/lib/validators";
import { isValidPlace } from "@/services/FormService";
import { IAppraiserFormPropertyLocationInput } from "@/models/Appraiser";

@Component
export default class AppraiserFormPropertyLocationStep extends Vue {
  protected propertyLocation: IAppraiserFormPropertyLocationInput = {
    cadastralReference: null,
    place: null,
  };

  protected handleContinue() {
    this.$v.propertyLocation.$touch();
    if (this.$v.$invalid) return;
    this.nextStep();
  }

  @Emit("next")
  nextStep() {
    return this.propertyLocation;
  }

  @Validations()
  validations = {
    propertyLocation: {
      cadastralReference: {
        required: requiredIf(function (propertyLocation) {
          return propertyLocation.place == null;
        }),
        alphaNum: alphaNum(),
        minLength: minLength(20),
        maxLength: maxLength(20),
      },
      place: (propertyLocation: IAppraiserFormPropertyLocationInput) => {
        if (propertyLocation.cadastralReference == null)
          return isValidPlace(propertyLocation.place);
        return true;
      },
    },
  };
}
