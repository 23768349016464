
import { Component, Emit, Vue } from "vue-property-decorator";
import {
  APPRAISER_FORM_REFURBISHMENT_TYPES,
  APPRAISER_FORM_STEPS,
} from "@/constants/tools";
import { MODAL_RESULTS } from "@/constants";
import { mapGetters } from "vuex";
import {
  IAppraiserCadastralInfoParams,
  IAppraiserCadastralInfoResponse,
  IAppraiserCadastralPropertyUnit,
  IAppraiserData,
  IAppraiserFormExtraData,
  IAppraiserFormPropertyLocationInput,
  IAppraiserNewValuationRequest,
  IAppraiserRefurbishmentTypesList,
  IAppraiserStepList,
} from "@/models/Appraiser";
import { IApiResponse, IGooglePlace, IModalResultList } from "@/models/Global";
import AppraiserFormPropertyLocationStep from "@/components/tools/appraiser-simulator/AppraiserFormPropertyLocationStep.vue";
import AppraiserFormPropertyUnitStep from "@/components/tools/appraiser-simulator/AppraiserFormPropertyUnitStep.vue";
import AppraiserFormExtraDataStep from "@/components/tools/appraiser-simulator/AppraiserFormExtraDataStep.vue";
import AppraiserFormResultStep from "@/components/tools/appraiser-simulator/AppraiserFormResultStep.vue";
import HiboLoader from "@/components/ui/HiboLoader.vue";

@Component({
  components: {
    AppraiserFormPropertyLocationStep,
    AppraiserFormPropertyUnitStep,
    AppraiserFormExtraDataStep,
    AppraiserFormResultStep,
    HiboLoader,
  },
  computed: {
    ...mapGetters("appraiser", {
      valuations: "getValuations",
      loading: "appraiserLoading",
    }),
  },
})
export default class AppraiserFormWrapper extends Vue {
  protected valuations!: IAppraiserData[];
  protected steps: IAppraiserStepList = APPRAISER_FORM_STEPS;
  protected modalResults: IModalResultList = MODAL_RESULTS;
  protected currentStep = this.steps.PROPERTY_LOCATION;
  protected formData: IAppraiserNewValuationRequest = {};
  protected propertyUnits?: IAppraiserCadastralPropertyUnit[] = [];
  protected refurbishmentTypes: IAppraiserRefurbishmentTypesList =
    APPRAISER_FORM_REFURBISHMENT_TYPES;
  protected result = "";

  protected errorResultStepFinish() {
    this.result = this.modalResults.ERROR;
    this.currentStep = this.steps.RESULT;
  }

  protected async setPropertyLocationDataAndContinueOrSkipNextStep(
    location: IAppraiserFormPropertyLocationInput
  ) {
    if (location.place) await this.handlePropertyAddress(location.place);
    else if (location.cadastralReference)
      await this.handleCadastralReference(location.cadastralReference);
  }

  protected async handlePropertyAddress(place: IGooglePlace) {
    const params: IAppraiserCadastralInfoParams = {
      address: place.formatted_address,
    };
    const cadastralInfoResponse: IApiResponse = await this.$store.dispatch(
      "appraiser/getCadastralInfo",
      params
    );
    if (cadastralInfoResponse.success) {
      const cadastralInfoResponseData: IAppraiserCadastralInfoResponse =
        cadastralInfoResponse.res;
      const propertyUnits = cadastralInfoResponseData.properties;
      this.setPropertyLocation(
        place.name,
        cadastralInfoResponseData.city,
        cadastralInfoResponseData.neighborhood,
        cadastralInfoResponseData.latitude,
        cadastralInfoResponseData.longitude
      );
      if (propertyUnits.length === 1)
        this.setPropertyUnitDataAndContinue(propertyUnits[0]);
      else this.preparePropertyUnitsAndContinue(propertyUnits);
    } else this.errorResultStepFinish();
  }

  protected async handleCadastralReference(cadastralReference: string) {
    const params: IAppraiserCadastralInfoParams = {
      cadastralReference: cadastralReference,
    };
    const cadastralInfoResponse: IApiResponse = await this.$store.dispatch(
      "appraiser/getCadastralInfo",
      params
    );
    if (cadastralInfoResponse.success) {
      const cadastralInfoResponseData: IAppraiserCadastralInfoResponse =
        cadastralInfoResponse.res;
      const propertyUnits = cadastralInfoResponseData.properties;
      if (propertyUnits.length === 1) {
        const shortAddress = cadastralInfoResponseData.fullAddress
          .split(",")
          .slice(0, 2)
          .join(",");
        this.setPropertyLocation(
          shortAddress,
          cadastralInfoResponseData.city,
          cadastralInfoResponseData.neighborhood,
          cadastralInfoResponseData.latitude,
          cadastralInfoResponseData.longitude
        );
        this.setPropertyUnitDataAndContinue(propertyUnits[0]);
      } else this.errorResultStepFinish();
    } else this.errorResultStepFinish();
  }

  protected setPropertyLocation(
    address: string,
    city: string,
    neighborhood: string,
    latitude: number,
    longitude: number
  ) {
    this.formData.address = address;
    this.formData.city = city;
    this.formData.neighborhood = neighborhood;
    this.formData.latitude = latitude;
    this.formData.longitude = longitude;
  }

  protected preparePropertyUnitsAndContinue(
    propertyUnits: IAppraiserCadastralPropertyUnit[]
  ) {
    this.propertyUnits = propertyUnits;
    this.currentStep = this.steps.PROPERTY_UNIT;
  }

  protected setPropertyUnitDataAndContinue(
    propertyUnit: IAppraiserCadastralPropertyUnit
  ) {
    this.formData.apartment = propertyUnit.label;
    this.formData.cadastralReference = propertyUnit.cadastralReference;
    this.formData.constructionYear = propertyUnit.constructionYear;
    this.formData.size = propertyUnit.size;
    this.formData.sizeUseful = propertyUnit.sizeUseful
      ? propertyUnit.sizeUseful
      : undefined;
    this.formData.floor = propertyUnit.floor;
    this.currentStep = this.steps.PROPERTY_EXTRA_DATA;
  }

  protected async setPropertyExtraDataAndCreateValuation(
    propertyExtraData: IAppraiserFormExtraData
  ) {
    this.formData.propertyTypeId = propertyExtraData?.propertyTypeId;
    this.formData.numRooms = propertyExtraData?.numRooms
      ? propertyExtraData?.numRooms
      : undefined;
    this.formData.constructionYear = propertyExtraData?.constructionYear;
    this.formData.propertyStatus = propertyExtraData?.propertyStatus;
    this.formData.hasExterior = propertyExtraData?.hasExterior;
    this.formData.hasGarden = propertyExtraData?.hasGarden;
    this.formData.hasCommunalPool = propertyExtraData?.hasCommunalPool;
    this.formData.hasPrivatePool = propertyExtraData?.hasPrivatePool;
    this.formData.hasGarage = propertyExtraData?.hasGarage;
    this.formData.hasSportsArea = propertyExtraData?.hasSportsArea;
    this.formData.hasTerrace = propertyExtraData?.hasTerrace;
    this.formData.hasBalcony = propertyExtraData?.hasBalcony;
    this.formData.hasYard = propertyExtraData?.hasYard;
    this.formData.hasStorageRoom = propertyExtraData?.hasStorageRoom;
    this.formData.hasAirConditioning = propertyExtraData?.hasAirConditioning;
    this.formData.hasElevator = propertyExtraData?.hasElevator;
    this.formData.hasAluminosisOrOther =
      propertyExtraData?.hasAluminosisOrOther;
    this.formData.hasTenants = propertyExtraData?.hasTenants;
    this.formData.hasIteDone = propertyExtraData?.hasIteDone;
    this.formData.isSocialHouse = propertyExtraData?.isSocialHouse;
    if (propertyExtraData?.refurbishment !== this.refurbishmentTypes.NONE)
      this.formData.refurbishment = propertyExtraData?.refurbishment;

    this.currentStep = this.steps.RESULT;

    const valuationCreationResponse: IApiResponse = await this.$store.dispatch(
      "appraiser/createValuation",
      this.formData
    );

    if (valuationCreationResponse.success) {
      this.result = this.modalResults.SUCCESS;
      const newValuationId: number = valuationCreationResponse.res;
      await this.openValuationOnSuccess(newValuationId);
    } else this.result = this.modalResults.ERROR;
  }

  protected async openValuationOnSuccess(newValuationId: number) {
    const newValuation = this.valuations.find(
      (valuation: IAppraiserData) => valuation.id === newValuationId
    );
    if (newValuation) {
      await this.$store.dispatch("appraiser/setCurrentValuation", newValuation);
      if (this.$route.name !== "appraiserSimulator")
        await this.$router.push({
          name: "appraiserSimulator",
        });
      this.close();
    } else this.result = this.modalResults.ERROR;
  }

  @Emit("close")
  close() {}
}
